import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Grid from '@material-ui/core/Grid'
import { Paper, Typography } from '@material-ui/core'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { makeStyles } from '@material-ui/core/styles'
import ActorCard from '../components/actor-card'
import NewsFeed from '../components/news-feed'
import { useEventApi } from '../services/events'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 960,
    margin: '0 auto',
  },
  media: {
    height: 200,
  },
  cardGrid: {
    maxWidth: 260,
    minWidth: 180,
  },
  newMembers: {
    maxWidth: 960,
    margin: '0 auto',
    padding: 20,
  },
}))

const IndexPage = ({
  data: {
    plantsGraph: { PlantActor: plantActors },
  },
}) => {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="Home" />
      <div className={classes.root}>
        {useEventApi(({ events }) => (
          <NewsFeed events={events.list().data} />
        ))}

        <Typography variant="h5" component="h3" style={{ margin: '20px 0' }}>
          New Members
        </Typography>
        <Paper className={classes.newMembers}>
          <Grid container spacing={3} justify="center">
            {plantActors.map(plantActor => (
              <Grid item xs key={plantActor.Name} className={classes.cardGrid}>
                <ActorCard
                  size="sm"
                  name={plantActor.Name}
                  image={plantActor.ImageSharp.childImageSharp.fixed.src}
                  species={plantActor.instance_of[0].Name}
                  handle={plantActor.Handle}
                />
              </Grid>
            ))}
          </Grid>
        </Paper>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  {
    plantsGraph {
      PlantActor(first: 6, orderBy: DoB_desc) {
        Name
        Image
        ImageSharp {
          childImageSharp {
            fixed(cropFocus: CENTER, fit: COVER, width: 120, height: 120) {
              src
            }
          }
        }
        Handle
        instance_of {
          Name
        }
      }
    }
  }
`
IndexPage.propTypes = {
  data: PropTypes.object,
}
