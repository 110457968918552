import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import EventTypeIcon from './event-type-icons'
import { Card, CardContent, Grid } from '@material-ui/core'
import EventActorPhrase from './event-actor-phrase'
import { TimeAgoShort } from './time-ago-short'

const useStyles = makeStyles(theme => ({
  eventContainer: {
    marginTop: 10,
  },
  eventTypeIcon: {
    width: 40,
  },
  cardContent: {
    [theme.breakpoints.down('sm')]: {
      padding: 10,
      '&:last-child': {
        paddingBottom: 10,
      },
    },
  },
  image: {
    maxWidth: 360,
    maxHeight: 300,
    objectFit: 'cover',
    marginBottom: 0,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 64,
    },
  },
}))

export default function NewsFeed({ events }) {
  const classes = useStyles()
  console.log({ events })

  return (
    <>
      <Typography component="h3" variant="h5">
        News Feed
      </Typography>
      {events &&
        events
          .sort(
            ({ data: event }, { data: event2 }) =>
              new Date(event2.createdAt) - new Date(event.createdAt)
          )
          .map(({ data: event }) => (
            <Card key={event.id} className={classes.eventContainer}>
              <CardContent className={classes.cardContent}>
                <Grid container spacing={3}>
                  <Grid item>
                    <EventTypeIcon
                      className={classes.eventTypeIcon}
                      eventType={event.eventType}
                    />
                  </Grid>
                  <Grid item>
                    <EventActorPhrase
                      actor={event.actor}
                      eventType={event.eventType}
                    />
                    <br />
                    <Typography variant="caption">
                      <TimeAgoShort datetime={event.createdAt} />
                    </Typography>
                    <Typography variant="body2">{event.text}</Typography>
                  </Grid>
                  {event.image && (
                    <Grid item xs={12}>
                      <img
                        className={classes.image}
                        src={event.image}
                        alt={event.title}
                      />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          ))}
    </>
  )
}

NewsFeed.propTypes = {
  events: PropTypes.array,
}
